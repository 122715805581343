<template>
  <div>
    <b-card>
      <div slot="header">
        <strong>할인율 검색 </strong>
        <small>mapped 기준 데이터입니다</small>
      </div>
      <b-form>
        <shop-preset class="mb-2" v-model="form.shop"></shop-preset>
        <brand-preset class="mb-2" v-model="form.brand"></brand-preset>

        <b-row class="mb-2">
          <b-col cols="12" lg="6">
            <small>그룹</small><br/>
            <b-form-checkbox-group name="group" v-model="form.group">
              <b-form-checkbox value="shop_id">SHOP</b-form-checkbox>
              <b-form-checkbox value="launch_date">시즌</b-form-checkbox>
              <b-form-checkbox value="brand_name">브랜드</b-form-checkbox>
              <b-form-checkbox value="category">카테고리(WCL 형태)</b-form-checkbox>
              <b-form-checkbox value="discount_rate">할인율</b-form-checkbox>
            </b-form-checkbox-group>
          </b-col>
          <b-col cols="12" lg="6">
            <div class="mt-2"><small>필터링</small></div>
            <b-form-checkbox-group name="filter" v-model="form.filter">
              <b-form-checkbox value="image">이미지있음</b-form-checkbox>
              <b-form-checkbox value="stock">재고있음</b-form-checkbox>
              <b-form-checkbox value="brand">브랜드있음</b-form-checkbox>
              <b-form-checkbox value="discountNull">할인율없음</b-form-checkbox>
              <b-form-checkbox value="discountMinus">할인율음수</b-form-checkbox>
            </b-form-checkbox-group>
          </b-col>
        </b-row>

        <div class="mb-2">
          <small>발란코드</small><br/>
          <b-form-textarea id="goodsNo" :rows="2" v-model="goods_no" placeholder="goods_no를 입력해주세요"></b-form-textarea>
          <small class="text-muted">콤마, 띄어쓰기, 탭, 엔터 등 숫자 이외의 모든 것으로 구분된 goods_no를 입력해주세요</small>
        </div>
      </b-form>
      <b-button class="m-1" variant="primary" @click="list">검색</b-button>
    </b-card>
    <div class="d-flex align-items-center">
      <div class="ml-auto">

        <b-button class="m-1" variant="success" @click="downGoodsByShop('xlsx')" :disabled="busy['goodsByShopXlsx']">전체 상품 정보 출력(XLSX) <b-spinner v-if="busy['goodsByShopXlsx']" small></b-spinner> </b-button>
        <b-button class="m-1" variant="success" @click="down('xlsx')">XLSX</b-button>
        <b-button class="m-1" variant="success" @click="down('txt')">Text</b-button>
      </div>
    </div>
    <c-table :table-data="items" :fields="fields" :perPage.sync="perPage" :isBusy="isBusy" :caption="items.length + ' 개'" @show-modal-clicked="showModal"></c-table>

    <b-modal title="상세 보기" size="xl" v-model="modal" @ok="modal = false">
      <div>
        <b-button class="m-1" variant="success" @click="downDetail('xlsx')">XLSX</b-button>
        <b-button class="m-1" variant="success" @click="downDetail('txt')">Text</b-button>
      </div>
      <c-table :table-data="items_detail" :fields="fields_detail" :perPage="10" :isBusy="isDetailBusy" :caption="items_detail.length + ' 개 상품'"></c-table>
    </b-modal>
    <iframe ref="ifr" style="width:1px;height:1px;visibility:hidden"></iframe>
  </div>
</template>
<script>
import {getMeta, getJson, postJson, getHost} from '@/shared/api'
import * as utils from '@/shared/utils'
import {down} from '@/shared/impexp'
import store from '@/store';
import qs from "querystring";
const {state:S} = store;

export default {
  name: 'DiscountRate',
  data() {
    return {
      cateW: ['WCL', 'WBA', 'WSH', 'WAC', 'WJW', 'WXX'],
      cateM: ['MCL', 'MBA', 'MSH', 'MAC', 'MJW', 'MXX'],
      cateU: ['UCL', 'UBA', 'USH', 'UAC', 'UJW', 'UXX', 'XXX'],
      shop: [],
      brand: [],
      shopMap: {},
      form: {
        shop: [],
        category: [],
        brand: [],
        group: ['shop_id', 'launch_date', 'discount_rate'],
        filter: ['image','stock'],
      },
      groupOrder: ['shop_id', 'launch_date', 'brand_name', 'category', 'discount_rate'],
      detailCond: {},
      brand_no: '',
      goods_no: '',
      items: [],
      items_detail: [],
      fields: [
        {key:'shop_name', sortable: true}, {key:'launch_date', sortable: true}, {key:'rate', sortable: true}, {key:'cnt', label:'상품수', sortable: true}, {key:'show_modal', label:'상세보기'}
      ],
      fields_detail: [
        {key: 'shop_id', label:'', style:{width:'120px'}, class: 'text-center'},
        {key: '_img60', label:'이미지', class: 'text-center'},
        {key: 'html2', label:'GoodsId<br/>SKU'},
        {key: 'html3', label:'발란코드'},
        {key: 'html1', label:'시즌<br/><span class="badge badge-light">카테고리</span>', class: 'text-center'},
        {key: 'html4', label:'상품정보'},
        {key: 'html5', label:'가격', class: 'text-right'},
      ],
      perPage: 1000,
      isBusy: false,
      busy: {goodsByShopXlsx:false},
      isDetailBusy: false,
      modal: false,
      abortController: null,
    }
  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'perPage');

    let meta = await getMeta();
    if (!meta) return;
    this.shop = meta.shop.sort((a,b)=>a.shop_id-b.shop_id);
    this.brand = meta.brand;
    this.shop.forEach(s=>{
      s.value = s.boutique;
      s.label = `${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    });
    this.brand.forEach(b=>{
      b.value = b.brand_no;
      b.label = `${b.brand_nm}(${b.brand_nm_kr})`;
    });

    // 초기 선택
    this.form.category = this.cateW.concat(this.cateM).concat(this.cateU);
  },
  async beforeDestroy() {
    if (this.abortController) this.abortController.abort();
  },

  sockets: {
    prog(obj) {
      switch (obj.type) {
        case 'done':
          this.busy[obj.group] = false;
          break;
      }
    },
  },
  watch: {
    perPage: function() {
      this.$utils.setStatus(this.$options.name, this, 'perPage');
    }
  },
  methods: {
    toggleCate() {
      this.form.category = this.form.category.length === 19 ? [] : this.cateW.concat(this.cateM).concat(this.cateU);
    },
    setCate(c) {
      let cates = this.cateW.concat(this.cateM).concat(this.cateU), filter;
      if (c === 'X') {
        filter = e=>~e.indexOf('X');
      } else if (c.length === 1) {
        filter = e=>e.startsWith(c);
      } else if (c.length === 2) {
        filter = e=>e.endsWith(c);
      } else {
        return;
      }
      let asis = this.form.category.filter(filter),
        all = cates.filter(filter),
        cur = new Set(this.form.category);
      if (asis.length === all.length) { // 선택한 셋 제거
        all.forEach(c=>cur.delete(c));
      } else { // 선택한 셋 전체추가
        all.forEach(c=>cur.add(c));
      }
      this.form.category = Array.from(cur);
    },
    async list() {
      let form = this.form;
      // validation
      if (form.shop.length === 0) return alert('SHOP 을 하나 이상 선택해주세요');

      let group = this.groupOrder.filter(g=>~this.form.group.indexOf(g));
      this.items.splice(0, this.items.length);
      this.isBusy = true;
      this.fields = group.map(e=>({key:e==='shop_id'?'shop_name':e, sortable:true}))
        .concat([{key:'cnt', label:'상품수', sortable: true}, {key:'show_modal', label:'상세보기'}]);
      let goods_no = this.goods_no ? this.goods_no.split(/\D+/g).map(e=>+e) : [];
      let shop = form.shop.length === this.shop.length ? [] : form.shop.map(e=>e.shop_id); // 전체 선택일 경우 비우기
      let brand = form.brand.map(e=>e.value);

      if (this.abortController) this.abortController.abort();
      this.abortController = new AbortController();
      let j;
      try {
        j = await postJson('/goods/discount_rate', {goods_no, form:{...this.form, shop, brand}}, {signal:this.abortController.signal});
      } catch (e) {
      } finally {
        this.isBusy = false;
      }
      if (!j) return;

      j.list.forEach(e=>{
        this.items.push({
          ...e._id,
          shop_name: this.shopMap[e._id.shop_id] ? this.shopMap[e._id.shop_id].label : `${e._id.shop_id}. (null)`,
          cnt: e.cnt
        });
      });
    },
    async downGoodsByShop(type) {
      // validation
      if (!this.form.shop.length || this.form.shop.length > 10 ) return alert('10개 이하의 SHOP을 선택해주세요');
      if (!confirm("모든 상품 정보를 추출하시겠습니까?")) {
        return;
      }
      let form = this.form;
      this.busy['goodsByShopXlsx'] = true;
      let shop = form.shop.length === this.shop.length ? [] : form.shop.map(e=>e.shop_id); // 전체 선택일 경우 비우기

      this.$refs.ifr.src = getHost() + `/goods/goods_by_shop_xlsx?filename=goods_discount_${utils.kstDT().replace(/[ :]/g,'_')}&type=${type}&` +qs.stringify({shops:shop.join(","), filters:form.filter.join(",")});
    },
    async down(type) {
      let group = this.groupOrder.filter(g=>~this.form.group.indexOf(g));
      down(this.items,
        ['shop_id', 'shop_name'].concat(group).concat(['cnt']),
        ['shop_id', 'shop_name'].concat(group).concat(['cnt']),
        `DiscountRate_${utils.dt()}`, type);
    },
    async downDetail(type) {
      let cols = ['shop_id', 'shop_name', 'goods_id', 'sku_id', 'goods_no', 'launch_date', 'brand_name', 'category', 'name', 'consumer', 'price', 'discount_rate'];
      down(this.items_detail,
        cols,
        cols,
        `DiscountRate_${this.detailCond.shop_name}_${utils.dt()}`, type);
    },
    async showModal(row) {
      let obj = JSON.parse(JSON.stringify(row.item));
      this.detailCond = {...obj};
      delete obj.shop_name;
      delete obj.cnt;

      let brand = this.form.brand.map(e=>e.value);
      let category = this.form.category;
      let goods_no = this.goods_no ? this.goods_no.split(/\D+/g).map(e=>+e) : [];

      this.isDetailBusy = true;
      this.modal = true;
      let j = await postJson('/goods/trace_discount', {cond:obj, category, brand, goods_no, filter:this.form.filter});
      this.items_detail = j.list.map(e=>{
        return {
          ...e,
          img: e.img_urls[0],
          shop_name: this.shopMap[e.shop_id].boutique,
          html2: `<a href="/#/mapped/${e.goods_id}" target="_blank">${e.goods_id}</a><br/>${e.sku_id}<br/><span class="badge badge-light">${e.category}</span>`,
          html3: e.goods_no ? `<a href="/#/goods/${e.goods_no}" target="_blank">${e.goods_no}</a>` : '',
          html1: `${e.launch_date}<br/><span class="badge badge-light>">${e.category}</span>`,
          html4: `<a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success">${e.shop_id}. ${e.shop}</a> <span class="badge badge-warning">${e.brand_name}</span>`
            + `<br/><span class="badge badge-light">${e.origin_category}</span><br/>`
            + `${e.name}<br/><small><b>${e.options && e.options.length ? e.options[0].optnm : ''}</b></small> ${e.options.map(e=>`<span class="badge badge-light">${e.Size}</span><span class="badge badge-success">${e.stock}</span>`).join(', ')}`,
          html5: (e.consumer === e.price ? '' : `<del>${e.consumer ? utils.rnc(e.consumer, 2) : ''} ${e.price_unit && e.price_unit !== 'KRW' ? e.price_unit : ''}</del><br/>`)
            + `${e.price ? utils.rnc(e.price, 2) : ''} ${e.price_unit && e.price_unit !== 'KRW' ? e.price_unit : ''}<br/>` + (e.consumer === e.price ? '' : `(${100-Math.round(e.price/e.consumer*100)}%)`)
        }
      });
      this.isDetailBusy = false;
    }
  }
}
</script>
